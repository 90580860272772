/**
 * Combines class names, ignoring falsy values. Named after similar util in Linaria.
 */
export function cx(...classNames: any[]): string {
  return classNames.filter(Boolean).join(' ');
}

export function splitBasenameExtension(filename: string) {
  const parts = filename.split('.');
  const extension = parts.pop() || '';
  const basename = parts.join('.');
  return { basename, extension };
}
