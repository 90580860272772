import { Navigate } from 'react-router';
import LoadError from '../auth/LoadError';
import { useUserState } from '../auth/UserState';
import { useWorkspaceId } from '../auth/WorkspaceState';
import useIsOperator from '../operator/useIsOperator';
import DelayedSpinner from '../shared/DelayedSpinner';
import supportLinks from '../shared/supportLinks';
import { getFlag } from '../util/flags';

export default function HomeRouter() {
  const { status } = useUserState();
  const workspaceId = useWorkspaceId();
  const isOperator = useIsOperator();

  switch (status) {
    case 'loading':
      return <DelayedSpinner className="my-8" />;
    case 'signedIn':
      if (isOperator) return <Navigate replace to="/operator" />;
      return <Navigate replace to={`/workspaces/${workspaceId}/files`} />;
    case 'error':
      return <LoadError />;
    case 'signedOut': {
      if (process.env.NODE_ENV === 'development' || getFlag('betaApi')) {
        return <Navigate replace to="auth" />;
      } else {
        window.location.href = supportLinks.about;
      }
    }
  }
}
