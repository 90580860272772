import { useWorkspaces } from '../auth/WorkspaceState';

/** Checks whether the current workspace role is transcript editor, content manager, or admin */
export function useIsTranscriptEditor(): boolean {
  const { currentRole } = useWorkspaces();
  return (
    currentRole === 'transcript-editor' ||
    currentRole === 'content-manager' ||
    currentRole === 'admin'
  );
}

/** Checks whether the current workspace role is content manager or admin */
export function useIsContentManager(): boolean {
  const { currentRole } = useWorkspaces();
  return currentRole === 'content-manager' || currentRole === 'admin';
}

/** Checks whether the current workspace role is admin */
export function useIsAdmin(): boolean {
  const { currentRole } = useWorkspaces();
  return currentRole === 'admin';
}
