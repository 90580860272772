// @ts-nocheck
import { Helmet } from 'react-helmet-async';

export default function HubSpotAnalytics() {
  return (
    <Helmet>
      <script
        type="text/javascript"
        id="hs-script-loader"
        async
        defer
        src="//js.hs-scripts.com/46816059.js"
      ></script>
    </Helmet>
  );
}
