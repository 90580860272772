import { HotkeysProvider } from '@blueprintjs/core';
import { ErrorBoundary } from '@sentry/react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactNode, Suspense } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { createRoutesFromElements, Navigate, Outlet, Route } from 'react-router';
import { RouterProvider } from 'react-router/dom';
import RequireAuth from './auth/RequireAuth';
import RequireWorkspace from './auth/RequireWorkspace';
import { SsoProviderContext } from './auth/sso/SsoContext';
import { UserStateProvider } from './auth/UserState';
import { WorkspaceProvider } from './auth/WorkspaceState';
import GoogleAnalytics from './GoogleAnalytics.tsx';
import HomeRouter from './home/HomeRouter';
import HubSpotAnalytics from './HubSpotAnalytics.tsx';
import { sentryCreateBrowserRouter } from './sentry.ts';
import { GlobalSpinner, NavigationSpinner } from './shared/GlobalSpinner.tsx';
import Header from './shared/Header';
import RouteScrollToTop from './shared/RouteScrollToTop';
import supportLinks from './shared/supportLinks.ts';
import { AvailableLanguageProvider } from './translation/AvailableLanguageProvider';
import { ErrorView, RouterEmbeddedErrorBoundary, RouterErrorBoundary } from './util/ErrorBoundary';
import { getFlag } from './util/flags';
import useCloudFunctionWarmer from './util/useCloudFunctionWarmer.ts';

function RecapProviders({ children }: { children: ReactNode }) {
  useCloudFunctionWarmer();
  return (
    <UserStateProvider>
      <WorkspaceProvider>
        {getFlag('sso') ? (
          <SsoProviderContext>
            <AvailableLanguageProvider>{children}</AvailableLanguageProvider>
          </SsoProviderContext>
        ) : (
          <AvailableLanguageProvider>{children}</AvailableLanguageProvider>
        )}
      </WorkspaceProvider>
    </UserStateProvider>
  );
}

function EmbeddedLayout() {
  return (
    <AvailableLanguageProvider>
      <Suspense fallback={<GlobalSpinner />}>
        <main className="w-full min-h-full flex flex-col relative">
          <NavigationSpinner />
          <Outlet />
        </main>
      </Suspense>
    </AvailableLanguageProvider>
  );
}

function RootLayout() {
  return (
    <RecapProviders>
      <GoogleAnalytics />
      <HubSpotAnalytics />
      <Header />
      <main id="main" className="min-h-full">
        <Suspense fallback={<GlobalSpinner />}>
          <Outlet />
        </Suspense>
      </main>
      <RouteScrollToTop />
    </RecapProviders>
  );
}

const router = sentryCreateBrowserRouter(
  createRoutesFromElements(
    <>
      {/* Embedded transcript viewer needs to be outside the regular Providers and header/footer */}
      <Route
        path="/embed/*"
        element={<EmbeddedLayout />}
        ErrorBoundary={RouterEmbeddedErrorBoundary}
        HydrateFallback={GlobalSpinner}
      >
        <Route path=":workspaceId/showcases/:showcaseId/*">
          <Route path="" lazy={() => import('./embed/EmbeddedShowcaseDetails.tsx')} />
          <Route path="search" lazy={() => import('./embed/EmbeddedShowcaseSearch.tsx')} />
          <Route path="files/:fileId" lazy={() => import('./embed/EmbeddedTranscriptViewer.tsx')} />
        </Route>
        <Route
          path=":workspaceId/files/:fileId"
          lazy={() => import('./embed/EmbeddedTranscriptViewer.tsx')}
        />
      </Route>
      <Route
        element={<RootLayout />}
        ErrorBoundary={RouterErrorBoundary}
        HydrateFallback={GlobalSpinner}
      >
        {/* This route is just to keep the RootLayout visible (if possible) when an error is thrown) */}
        <Route element={<Outlet />} ErrorBoundary={RouterErrorBoundary}>
          <Route path="/" element={<HomeRouter />} />

          <Route path="/licenses" lazy={() => import('./licenses/LicensesPage')} />
          <Route path="/login" lazy={() => import('./auth/AuthPage')} />
          <Route path="/signup" lazy={() => import('./auth/AuthPage')} />
          <Route path="/auth" lazy={() => import('./auth/AuthPage')} />
          {getFlag('sso') && (
            <Route path="/auth/sso/callback" lazy={() => import('./auth/sso/SamlCallback')} />
          )}

          <Route element={<RequireAuth />}>
            <Route
              path="/workspaces/:workspaceId/join"
              lazy={() => import('./workspace/WorkspaceJoinPage')}
            />
            <Route path="/workspaces/:workspaceId/*" element={<RequireWorkspace />}>
              <Route path="" element={<Navigate replace to="files" />} />
              <Route
                path="settings"
                lazy={() => import('./workspace/admin/WorkspaceSettingsNav')}
                ErrorBoundary={RouterErrorBoundary}
              >
                <Route path="" element={<Navigate to="general" replace />} />
                <Route path="general" lazy={() => import('./workspace/admin/GeneralPage')} />
                <Route path="members" lazy={() => import('./workspace/admin/MembersPage')} />
                <Route
                  path="integrations"
                  lazy={() => import('./workspace/admin/IntegrationsPage')}
                />
                <Route path="credit" lazy={() => import('./workspace/admin/CreditHistoryPage')} />
                <Route path="plan" lazy={() => import('./workspace/admin/PlanPage')} />
                <Route path="referrals" lazy={() => import('./workspace/admin/ReferralsPage')} />
                <Route path="showcases" lazy={() => import('./showcases/ShowcasesPage')}>
                  <Route
                    ErrorBoundary={RouterErrorBoundary}
                    path=":showcaseId"
                    lazy={() => import('./showcases/ShowcaseDetailsPage')}
                  />
                  <Route path="*" lazy={() => import('./showcases/SelectShowcase')} />
                </Route>
                <Route path="*" lazy={() => import('./util/NotFoundPage')} />
              </Route>
              <Route path="search" lazy={() => import('./search/WorkspaceSearchPage')} />
              <Route
                path="corrections"
                lazy={() => import('./corrections/PendingCorrectionsPage.tsx')}
              >
                <Route
                  path=":fileId/:correctionId"
                  lazy={() => import('./corrections/ReviewCorrection.tsx')}
                />
                <Route path="*" lazy={() => import('./corrections/SelectCorrection.tsx')} />
              </Route>
              <Route path="files" lazy={() => import('./files/FilesPage')} />
              <Route
                path="files/panopto"
                lazy={() => import('./integrations/panopto/PanoptoVideosPage.tsx')}
              />
              <Route
                path="files/panopto/:videoId"
                lazy={() => import('./integrations/panopto/PanoptoDetailsPage.tsx')}
              />
              <Route
                path="files/youtube"
                lazy={() => import('./integrations/youtube/YouTubeVideosPage.tsx')}
              />
              <Route
                path="files/youtube/:videoId"
                lazy={() => import('./integrations/youtube/YouTubeDetailsPage.tsx')}
              />
              <Route
                path="files/brightcove"
                lazy={() => import('./integrations/brightcove/BrightcoveVideosPage.tsx')}
              />
              <Route
                path="files/brightcove/:videoId"
                lazy={() => import('./integrations/brightcove/BrightcoveDetailsPage.tsx')}
              />
              <Route
                path="files/googledrive"
                lazy={() => import('./integrations/googleDrive/GoogleDriveFilesPage.tsx')}
              />
              <Route
                path="files/googledrive/:videoId"
                lazy={() => import('./integrations/googleDrive/GoogleDriveDetailsPage.tsx')}
              />
              <Route path="files/box" lazy={() => import('./integrations/box/BoxFilesPage.tsx')} />
              <Route
                path="files/box/:videoId"
                lazy={() => import('./integrations/box/BoxDetailsPage.tsx')}
              />
              <Route
                path="files/kaltura"
                lazy={() => import('./integrations/kaltura/KalturaFilesPage.tsx')}
              />
              <Route
                path="files/kaltura/:videoId"
                lazy={() => import('./integrations/kaltura/KalturaEntryDetailsPage.tsx')}
              />
              <Route path="files/:fileId" lazy={() => import('./details/RecapDetailsPage')} />
              <Route
                path="files/:fileId/analysis"
                lazy={() => import('./transcriptAccuracyAnalysis/TranscriptAccuracyAnalysis')}
              />
            </Route>

            <Route path="/account" lazy={() => import('./account/AccountPage')} />
            <Route path="/account/delete" lazy={() => import('./account/DeleteAccountPage')} />

            <Route
              path="/operator/:selectedWorkspaceId?"
              lazy={() => import('./operator/OperatorPage')}
            />
            <Route path="/operator/swagger" lazy={() => import('./operator/OperatorSwaggerPage')} />
          </Route>

          <Route
            path="/privacy"
            Component={() => {
              window.location.replace(supportLinks.privacy);
              return null;
            }}
          />
          <Route
            path="/terms"
            Component={() => {
              window.location.replace(supportLinks.terms);
              return null;
            }}
          />

          <Route path="*" lazy={() => import('./util/NotFoundPage')} />
        </Route>
      </Route>
    </>,
  ),
);

const queryClient = new QueryClient({ defaultOptions: { queries: { retry: 1 } } });

export default function App() {
  return (
    <ErrorBoundary fallback={({ error }) => <ErrorView debugInfo={`${error}`} />}>
      <HelmetProvider>
        <HotkeysProvider dialogProps={{ globalGroupName: 'Recap Keyboard Shortcuts' }}>
          <QueryClientProvider client={queryClient}>
            <RouterProvider router={router} />
          </QueryClientProvider>
        </HotkeysProvider>
      </HelmetProvider>
    </ErrorBoundary>
  );
}
