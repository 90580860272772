import { authFetch } from '../../util/api';
import { SamlAuthRequest, SamlConfig, SamlProviderMetadata, SsoProvider } from './types';

/**
 * Check if an email domain has an associated SSO provider
 * @param email The email address to check
 * @returns Promise with the check result
 */
export async function checkSsoProvider(
  email: string,
): Promise<{ ssoEnabled: boolean; providerId?: string }> {
  const response = await authFetch('/sso/check', {
    method: 'POST',
    body: JSON.stringify({ email }),
  });
  return response;
}

/**
 * Create a SAML authentication request
 * @param email The email address of the user
 * @param providerId The ID of the SSO provider
 * @returns Promise with the authentication request
 */
export async function createAuthRequest(
  email: string,
  providerId: string,
): Promise<SamlAuthRequest> {
  const response = await authFetch('/sso/auth-request', {
    method: 'POST',
    body: JSON.stringify({ email, providerId }),
  });
  return response;
}

/**
 * Handle SAML response from Identity Provider
 * @param samlResponse The SAML response from the Identity Provider
 * @param relayState The relay state from the SAML request
 * @returns Promise with the authentication response
 */
export async function handleSamlResponse(
  samlResponse: string,
  relayState: string,
): Promise<{ token: string; user: { email: string; displayName?: string }; redirectUrl?: string }> {
  const response = await authFetch('/sso/handle-response', {
    method: 'POST',
    body: JSON.stringify({ samlResponse, relayState }),
  });
  return response;
}

/**
 * Get all SSO providers (admin only)
 * @returns Promise with the list of providers
 */
export async function getProviders(): Promise<{ providers: SsoProvider[] }> {
  return { providers: [] };
  // const response = await authFetch('/sso/providers'); return response;
}

/**
 * Create a new SSO provider (admin only)
 * @param provider The provider configuration
 * @returns Promise with the created provider
 */
export async function createProvider(
  provider: Omit<SamlConfig, 'id' | 'createdAt' | 'updatedAt'>,
): Promise<SamlConfig> {
  const response = await authFetch('/sso/providers', {
    method: 'POST',
    body: JSON.stringify(provider),
  });
  return response;
}

/**
 * Delete an SSO provider (admin only)
 * @param providerId The ID of the provider to delete
 * @returns Promise with the result
 */
export async function deleteProvider(providerId: string): Promise<{ success: boolean }> {
  const response = await authFetch(`/sso/providers/${providerId}`, {
    method: 'DELETE',
  });
  return response;
}

/**
 * Toggle provider enabled status (admin only)
 * @param providerId The ID of the provider to toggle
 * @param enabled Whether the provider should be enabled
 * @returns Promise with the result
 */
export async function toggleProvider(
  providerId: string,
  enabled: boolean,
): Promise<{ success: boolean }> {
  const response = await authFetch(`/sso/providers/${providerId}/toggle`, {
    method: 'POST',
    body: JSON.stringify({ enabled }),
  });
  return response;
}

/**
 * Get provider metadata (admin only)
 * @param providerId The ID of the provider
 * @returns Promise with the provider metadata
 */
export async function getProviderMetadata(providerId: string): Promise<SamlProviderMetadata> {
  const response = await authFetch(`/sso/providers/${providerId}/metadata`);
  return response;
}
