import { useQuery } from '@tanstack/react-query';
import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { matchPath, useLocation } from 'react-router';
import useIsOperator from '../operator/useIsOperator';
import { authFetch } from '../util/api';
import { useUserState } from './UserState';
import { Workspace, WorkspaceMemberRole } from './workspace';

type WorkspaceWithRole = {
  workspace: Workspace;
  role: WorkspaceMemberRole;
};

type WorkspaceStateInternal = {
  status: 'loaded' | 'loading' | 'error';
  workspaces: WorkspaceWithRole[];
};

export type WorkspaceState = WorkspaceStateInternal & {
  current?: Workspace;
  currentRole?: WorkspaceMemberRole;
  reload: () => void;
};

const loadingState: WorkspaceState = { status: 'loading', workspaces: [], reload: () => {} };

const WorkspaceStateContext = createContext<WorkspaceState>(loadingState);

export function WorkspaceProvider({ children }: { children: ReactNode }) {
  const { status, authClaims } = useUserState();

  const [workspaceState, setWorkspaceState] = useState<WorkspaceStateInternal>(loadingState);

  const reload = useCallback(async () => {
    // This does not set status to loading, since we want to reload silently sometimes.
    try {
      if (status === 'signedIn') {
        const workspaceIds = Array.from(new Set(authClaims?.workspaces?.map(({ id }: any) => id)));
        const workspaces: Workspace[] = await Promise.all(
          workspaceIds.map((id) => authFetch(`/get_workspace?workspaceId=${id}`)) || [],
        );

        workspaces.sort((a, b) => a.workspaceName.localeCompare(b.workspaceName));

        const workspacesWithRoles: WorkspaceWithRole[] = [];
        for (const workspace of workspaces) {
          const role: WorkspaceMemberRole | undefined = authClaims?.workspaces?.find(
            (claim: any) => claim.id === workspace.workspaceId,
          )?.role;
          // Ignore workspaces that don’t have a role
          if (role) {
            workspacesWithRoles.push({ workspace, role });
          }
        }

        setWorkspaceState({ status: 'loaded', workspaces: workspacesWithRoles });
      } else {
        setWorkspaceState(loadingState);
      }
    } catch (error) {
      console.error('Error loading workspaces', error);
      setWorkspaceState({ status: 'error', workspaces: [] });
    }
  }, [authClaims, status]);

  useEffect(() => {
    reload();
  }, [reload]);

  // Find current workspace (all users except operators)
  const currentWorkspaceId = useWorkspaceId();
  const currentWorkspace = workspaceState.workspaces.find(
    (workspace) => workspace?.workspace.workspaceId === currentWorkspaceId,
  );

  // Allow operators to load any workspace
  const isOperator = useIsOperator();
  const { data: operatorWorkspaceData, refetch: refetchOperatorWorkspace } = useQuery({
    queryKey: ['workspace', currentWorkspaceId],
    queryFn: () => authFetch(`/get_workspace?workspaceId=${currentWorkspaceId}`),
    enabled: isOperator && !!currentWorkspaceId,
  });

  const operatorWorkspace: WorkspaceWithRole | undefined = useMemo(() => {
    if (operatorWorkspaceData) {
      return { workspace: operatorWorkspaceData, role: 'admin' };
    }
    return undefined;
  }, [operatorWorkspaceData]);

  const fullReload = useCallback(() => {
    reload();
    refetchOperatorWorkspace();
  }, [refetchOperatorWorkspace, reload]);

  const value: WorkspaceState = useMemo(
    () => ({
      ...workspaceState,
      reload: fullReload,
      current:
        status === 'signedIn'
          ? currentWorkspace?.workspace || operatorWorkspace?.workspace
          : undefined,
      currentRole:
        status === 'signedIn' ? currentWorkspace?.role || operatorWorkspace?.role : undefined,
    }),
    [currentWorkspace, fullReload, operatorWorkspace, status, workspaceState],
  );
  return <WorkspaceStateContext.Provider value={value}>{children}</WorkspaceStateContext.Provider>;
}

/** Returns current workspace ID from route, localStorage, or user’s authClaims */
export function useWorkspaceId(): string {
  const userState = useUserState();
  const { pathname } = useLocation();

  const routeMatch = matchPath('/workspaces/:workspaceId/*', pathname);
  const routeWorkspaceId = routeMatch?.params.workspaceId;

  const userUid = userState.user?.uid;
  const cacheKey = `recap.cache.${userUid}-workspace`;
  const cachedWorkspaceId = localStorage.getItem(cacheKey);

  const defaultWorkspaceId = userState.authClaims?.workspaces?.[0]?.id;

  const currentWorkspaceId = routeWorkspaceId || cachedWorkspaceId || defaultWorkspaceId;

  useEffect(() => {
    if (currentWorkspaceId && userUid) localStorage.setItem(cacheKey, currentWorkspaceId);
  }, [cacheKey, currentWorkspaceId, userUid]);

  return currentWorkspaceId;
}

export function useWorkspaces(): WorkspaceState {
  const context = useContext(WorkspaceStateContext);
  if (context === undefined) {
    throw new Error('useWorkspaces must be used within a WorkspaceProvider');
  }
  return context;
}
