import { ReactNode, createContext, useContext, useEffect, useState } from 'react';
import { SsoProvider } from './types';
import { getProviders } from './ssoService';

interface SsoContextType {
  providers: SsoProvider[];
  loading: boolean;
  error: Error | null;
  refreshProviders: () => Promise<void>;
}

const SsoContext = createContext<SsoContextType | null>(null);

export function useSso() {
  const context = useContext(SsoContext);
  if (!context) {
    throw new Error('useSso must be used within a SsoProviderContext');
  }
  return context;
}

interface SsoProviderContextProps {
  children: ReactNode;
}

export function SsoProviderContext({ children }: SsoProviderContextProps) {
  const [providers, setProviders] = useState<SsoProvider[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);

  const fetchProviders = async () => {
    try {
      setLoading(true);
      setError(null);
      const response = await getProviders();
      setProviders(response.providers || []);
    } catch (err) {
      console.error('Error fetching SSO providers:', err);
      setError(err instanceof Error ? err : new Error('Failed to fetch SSO providers'));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchProviders();
  }, []);

  const value = {
    providers,
    loading,
    error,
    refreshProviders: fetchProviders,
  };

  return <SsoContext.Provider value={value}>{children}</SsoContext.Provider>;
}
