const marketingSiteBase = 'https://recap-innovations.com';

const supportLinks = {
  // External pages
  about: marketingSiteBase,
  bookDemo: `${marketingSiteBase}/book-a-demo`,
  help: `${marketingSiteBase}/help`,
  privacy: `${marketingSiteBase}/privacy`,
  terms: `${marketingSiteBase}/terms`,
  accessibility: `${marketingSiteBase}/accessibility`,
  security: `${marketingSiteBase}/security`,
  contentSubmission: `${marketingSiteBase}/content-submission`,
  contactForm: 'https://share.hsforms.com/1S3w1Hu5GRS-uC_favB1KWwrvfi3',

  // Internal pages
  licenses: `/licenses`,
};

export default supportLinks;
