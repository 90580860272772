import { getAuth } from 'firebase/auth';
import loadUser from '../auth/loadUser';
import config, { firebaseApp } from '../config';

const { apiBase } = config;

export const jsonHeaders = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
};

// This generates the base headers for authentication
// It does not include JSON headers, since useAuthFetch needs to support non-JSON requests
export const getBaseHeaders = async (): Promise<Record<string, string>> => {
  let token;
  try {
    await loadUser();
    token = await getAuth(firebaseApp).currentUser?.getIdToken();
  } catch (error) {
    console.error('Failed to get token', error);
  }

  if (token) return { Authorization: `Bearer ${token}` };
  return {};
};

// This wraps fetch with the Firebase auth token and JSON handling
export const authFetch = async (
  url: string,
  { headers: customHeaders, ...options }: RequestInit = {},
  isJson?: boolean,
) => {
  const headers = {
    ...(await getBaseHeaders()),
    // Allow not setting content-type (needed for multipart form uploads or downloads)
    ...(isJson !== false ? jsonHeaders : {}),
    // Spread user-defined headers *after* to allow overrides
    ...customHeaders,
  };

  const fetchOptions = { ...options, headers };
  const response = await fetch(`${apiBase}${url}`, fetchOptions);
  if (isJson === false) {
    if (!response.ok) {
      throw response;
    }
    return response;
  }
  const json = await response.json();
  if (!response.ok) {
    throw json || response.status;
  } else {
    return json;
  }
};
