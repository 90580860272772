import { getAuth, onAuthStateChanged, User } from 'firebase/auth';
import { firebaseApp } from '../config';

// Helper function to await initial loading of Firebase user (resolves once; not reactive to auth changes)
export default async function loadUser(): Promise<User | null> {
  const auth = getAuth(firebaseApp);
  return new Promise((resolve) => {
    onAuthStateChanged(auth, resolve);
  });
}
