import { Outlet } from 'react-router';
import DelayedSpinner from '../shared/DelayedSpinner';
import LoadError from './LoadError';
import WorkspaceNotFound from './WorkspaceNotFound';
import { useWorkspaces } from './WorkspaceState';

export default function RequireWorkspace() {
  const { status, current } = useWorkspaces();

  switch (status) {
    case 'loaded':
      return current ? <Outlet /> : <WorkspaceNotFound />;
    case 'loading':
      return <DelayedSpinner className="my-8" />;
    case 'error':
      return <LoadError />;
  }
}
