import { Spinner } from '@blueprintjs/core';
import { useNavigation } from 'react-router';

export function GlobalSpinner() {
  return (
    <div className="fixed top-20 inset-x-0 mx-auto p-2 bg-gray-100 dark:bg-gray-500 w-min shadow-md rounded-full z-50">
      <Spinner size={24} />
    </div>
  );
}

// Displays a global spinner while react-router is loading a route’s code or loader data
export function NavigationSpinner() {
  const { state } = useNavigation();
  return state === 'loading' && <GlobalSpinner />;
}
