import { getAuth, onAuthStateChanged, User } from 'firebase/auth';
import { createContext, ReactNode, useCallback, useContext, useEffect, useState } from 'react';
import config, { firebaseApp } from '../config';

type UserStatus = 'signedIn' | 'signedOut' | 'loading' | 'error';

export type UserState = {
  status: UserStatus;
  user?: User;
  authClaims?: Record<string, any>;
};

export const DisplayNameMaxLength = 80;

const loadingState: UserState = { status: 'loading', user: undefined };

const UserStateContext = createContext<UserState>(loadingState);
const UserReloadContext = createContext<() => void>(() => {});

export function UserStateProvider({ children }: { children: ReactNode }) {
  const [userState, setUserState] = useState<UserState>(loadingState);

  const reloadUser = useCallback(async (allowRetry = false) => {
    // This does not set UserStatus to loading, since we want to reload silently sometimes.
    try {
      const user = getAuth(firebaseApp).currentUser;
      await user?.reload();
      if (!user) throw new Error('Invalid user received');
      const authClaims = (await user?.getIdTokenResult(true))?.claims;
      setUserState({ status: 'signedIn', user, authClaims });
    } catch (error) {
      // Cloud function cold start can be slow, so give it some time and retry
      if (allowRetry) {
        await new Promise((resolve) => setTimeout(resolve, 5000));
        await reloadUser();
      } else {
        console.error('Error reloading user', error);
        setUserState({ status: 'error', user: undefined });
      }
    }
  }, []);

  useEffect(() => {
    const auth = getAuth(firebaseApp);
    onAuthStateChanged(auth, async (firebaseUser) => {
      if (firebaseUser) {
        setUserState({ status: 'loading' });
        reloadUser(true);
      } else {
        setUserState({ status: 'signedOut', user: undefined });
      }
    });
  }, [reloadUser]);

  return (
    <UserStateContext.Provider value={userState}>
      <UserReloadContext.Provider value={reloadUser}>{children}</UserReloadContext.Provider>
    </UserStateContext.Provider>
  );
}

export function useUserState() {
  const context = useContext(UserStateContext);
  if (context === undefined) {
    throw new Error('useUserState must be used within a UserStateProvider');
  }
  return context;
}

export function useReloadUser() {
  const context = useContext(UserReloadContext);
  if (context === undefined) {
    throw new Error('useReloadUser must be used within a UserStateProvider');
  }
  return context;
}

export async function signOut() {
  await getAuth(firebaseApp).signOut();
  // Clear cached data (like last-used workspace ID), but not prefs
  Object.keys(localStorage).forEach((key) => {
    if (key.startsWith('recap.cache.')) {
      localStorage.removeItem(key);
    }
  });

  try {
    // Lazy-load to avoid bringing magic-sdk into the main index.js
    const { Magic } = await import('magic-sdk');
    const magicInstance = new Magic(config.magicPublishableKey);
    await magicInstance.user.logout();
  } catch (error) {
    console.error('Failed to logout Magic user', error);
  }
  window.location.href = '/auth';
}
