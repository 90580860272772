import { AnchorButton } from '@blueprintjs/core';
import { getAuth } from 'firebase/auth';
import { firebaseApp } from '../config';
import supportLinks from '../shared/supportLinks';

export function ReportProblemButton() {
  return (
    <AnchorButton
      intent="primary"
      minimal
      icon="issue"
      text="Report a Problem"
      target="_blank"
      href={contactLink(
        'Recap Problem Report',
        'Please describe the problem experienced in Recap: ',
      )}
    />
  );
}

export function SuggestFeatureButton() {
  return (
    <AnchorButton
      intent="primary"
      minimal
      icon="lightbulb"
      text="Suggest a Feature"
      target="_blank"
      href={contactLink('Recap Feature Suggestion', 'Please describe the feature you would like: ')}
    />
  );
}

// Launches a form pre-filled with email and Recap system info
export function contactLink(subject: string, prompt = '', debugInfo = '') {
  const body = `${prompt}\n\n———\nRecap Web on ${navigator.userAgent}\n${debugInfo}`;
  const url = new URL(supportLinks.contactForm);
  try {
    url.searchParams.append('email', getAuth(firebaseApp).currentUser?.email || '');
  } catch (error) {}
  url.searchParams.append('subject', subject);
  url.searchParams.append('content', body);
  return url.toString();
}
