import { Spinner, SpinnerProps } from '@blueprintjs/core';
import { useEffect, useState } from 'react';

const DelayedSpinner = (props: SpinnerProps) => {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => setVisible(true), 500);
    return () => clearTimeout(timer);
  });

  return visible ? <Spinner {...props} /> : null;
};

export default DelayedSpinner;
