import { AnchorButton, Button, Icon, NonIdealState } from '@blueprintjs/core';
import { captureException } from '@sentry/react';
import { useEffect } from 'react';
import { useRouteError } from 'react-router';
import { contactLink } from '../account/FeedbackButtons';

export function RouterErrorBoundary() {
  const error = useRouteError() as Error;
  useEffect(() => {
    console.error(error);
    captureException(error);
  }, [error]);

  return <ErrorView debugInfo={`${JSON.stringify(error)}`} />;
}

export function ErrorView({ debugInfo }: { debugInfo?: string }) {
  return (
    <NonIdealState
      title="Sorry, something went wrong."
      description="Try reloading the page, and contact us if the issue persists."
      icon={<Icon icon="error" intent="danger" size={48} />}
      action={
        <>
          <div className="flex gap-2 pb-4">
            <Button onClick={() => window.location.reload()}>Reload Page</Button>
            <AnchorButton
              text="Contact Us"
              target="_blank"
              href={contactLink(
                'Recap Web Error Report',
                'Please describe what actions led to the error, and include any other information or screenshots: ',
                window.location.href,
              )}
            />
          </div>
          {process.env.NODE_ENV === 'development' && debugInfo}
        </>
      }
    />
  );
}

export function RouterEmbeddedErrorBoundary() {
  const error = useRouteError();
  useEffect(() => {
    console.error(error);
    captureException(error);
  }, [error]);

  const errorString = JSON.stringify((error as any)?.message || error);
  return (
    <NonIdealState
      className="m-auto p-2"
      title="Sorry, something went wrong."
      description="Please contact the owner of this page to resolve this error."
      icon={<Icon icon="error" intent="danger" size={48} />}
      action={
        <>
          <div className="flex gap-2 pb-4">
            <Button onClick={() => window.location.reload()}>Reload Page</Button>
            <AnchorButton
              icon="send-message"
              text="Report a Problem"
              target="_blank"
              href={contactLink(
                'Recap Embed Error Report',
                'Please retain the information below. You may include any additional information, such as actions taken before the error happened.',
                `${window.location.pathname}  ${errorString}`,
              )}
            />
          </div>
          {process.env.NODE_ENV === 'development' && errorString}
        </>
      }
    />
  );
}
