// @ts-nocheck
import { Helmet } from 'react-helmet-async';
import config from './config';
import { useEffect } from 'react';

export default function GoogleAnalytics() {
  useEffect(() => {
    // Google tag manager
    if (config.googleAnalyticsMeasurementId) {
      window.dataLayer = window.dataLayer || [];
      function gtag() {
        dataLayer.push(arguments);
      }
      gtag('js', new Date());

      gtag('config', config.googleAnalyticsMeasurementId);
    }
  }, []);

  return (
    <Helmet>
      <script
        async
        src={`https://www.googletagmanager.com/gtag/js?id=${config.googleAnalyticsMeasurementId}`}
      />
    </Helmet>
  );
}
