import { useEffect, useState } from 'react';

// Locally-persisted preferences. Values must be JSON-serializable.

type PrefKey =
  | 'flagOverrides'
  | 'uploadLanguageCode'
  | 'exportTranscriptFormat'
  | 'exportTranscriptIncludeTimestamps'
  | 'captionLineLength'
  | 'reviewCorrectionHelpHidden'
  | 'youTubeImportHelpHidden'
  | 'referralBadgeClicked'
  | 'detailsPanelDirection'
  // Video player preferences
  | 'player.captionOptions'
  | 'player.audioDescriptionOptions'
  | 'player.highContrast'
  | 'player.playbackRate'
  | 'player.volume'
  | 'player.captionsEnabled';

const prefix = 'recap.preference.';

export function deletePref(key: PrefKey): void {
  try {
    localStorage.removeItem(prefix + key);
  } catch (error) {}
}

export function setPref<T>(key: PrefKey, value: T): void {
  try {
    localStorage.setItem(prefix + key, JSON.stringify(value));
  } catch (error) {}
}

export function getPref<T>(key: PrefKey, defaultValue: T): T {
  try {
    const value = localStorage.getItem(prefix + key);
    return value !== null ? JSON.parse(value) : defaultValue;
  } catch (error) {
    return defaultValue;
  }
}

export function usePref<T>(
  key: PrefKey,
  defaultValue: T,
): [T, (value: T | ((prev: T) => T)) => void] {
  const [value, setValue] = useState(() => getPref(key, defaultValue));

  useEffect(() => {
    setPref(key, value);
  }, [key, value]);

  return [value, setValue];
}
