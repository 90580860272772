import { Icon, Tooltip } from '@blueprintjs/core';
import { useState } from 'react';
import { Link, useLocation } from 'react-router';
import { useWorkspaceId } from '../auth/WorkspaceState';
import { getPref, setPref } from '../util/prefs';
import { useMediaQuery } from './useMediaQuery';

export function ReferralBadge() {
  const workspaceId = useWorkspaceId();
  const [hasClickedBefore, setHasClickedBefore] = useState(() =>
    getPref('referralBadgeClicked', false),
  );
  const onReferralsPage = useLocation().pathname.includes('/referrals');
  const isMobile = useMediaQuery('(max-width: 768px)');
  // Track when the user clicks the badge
  const handleClick = () => {
    setPref('referralBadgeClicked', true);
    setHasClickedBefore(true);
  };

  if (!workspaceId) return null;

  const tooltipContent = (
    <div className="p-2 max-w-xs">
      <h4 className="font-bold mb-1">Refer & Earn Program</h4>
      <p className="mb-2">Invite colleagues to try our video accessibility services!</p>
      <ul className="list-disc pl-4 space-y-1 text-sm">
        <li>Earn 500-1000 free transcription minutes for each business referral</li>
        <li>Your referral may also get 500 bonus minutes</li>
        <li>Create personalized invitation links</li>
        <li>Track invitations and conversions</li>
      </ul>
    </div>
  );

  return (
    <Tooltip
      content={tooltipContent}
      position="bottom"
      intent="primary"
      openOnTargetFocus={true}
      hoverOpenDelay={300}
      disabled={isMobile || onReferralsPage}
      interactionKind="hover"
    >
      <Link
        to={`/workspaces/${workspaceId}/settings/referrals`}
        className={`relative group focus:outline-none focus:ring-2 focus:ring-amber-500 focus:ring-opacity-50 rounded-full flex items-center my-1`}
        aria-label="Refer and earn program"
        title="Refer colleagues and earn free minutes"
        onClick={handleClick}
      >
        <div className="flex items-center justify-center px-2 sm:px-3 py-1 sm:py-1.5 rounded-full bg-gradient-to-r from-amber-300 via-yellow-200 to-amber-300 dark:from-amber-800/50 dark:via-amber-700/50 dark:to-amber-800/50 text-amber-800 dark:text-amber-200 font-semibold text-xs shadow-md hover:shadow-lg transition-all duration-300 border border-amber-400 dark:border-amber-600/50 overflow-hidden">
          <span className="relative flex items-center">
            <Icon icon="dollar" className="mr-1" size={12} />
            <span className="whitespace-nowrap">Refer &amp; earn!</span>
          </span>
        </div>

        {/* Pulsing dot to draw attention - only show for users who haven't clicked before */}
        {!hasClickedBefore && (
          <span className="absolute -top-1 -right-1 flex h-3 w-3">
            <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-amber-400 dark:bg-amber-600/50 opacity-75"></span>
            <span className="relative inline-flex rounded-full h-3 w-3 bg-amber-500 dark:bg-amber-700/50"></span>
          </span>
        )}
      </Link>
    </Tooltip>
  );
}
