import { Popover, H5, Switch, Divider, Button, Classes } from '@blueprintjs/core';
import { getFlags, getFlagOverrides, FlagName, setFlagOverride, resetFlagOverrides } from './flags';

/**
 * Provides UI to view and override flags from the user’s localStorage.
 * The button is visible when flags are overridden, in development mode, or with ?flagOverrides query param is present.
 */
export default function FlagOverrides() {
  const flags = getFlags();
  const flagOverrides = getFlagOverrides();
  const overriddenFlagCount = Object.keys(flagOverrides).length;
  const showOverrides =
    overriddenFlagCount > 0 ||
    process.env.NODE_ENV === 'development' ||
    window.location.search.includes('flagOverrides');

  if (!showOverrides) return null;

  return (
    <Popover
      targetProps={{ style: { display: 'flex' } }}
      className="flex"
      content={
        <div className="p-3 text-gray-700 dark:text-gray-300 min-">
          <H5>Flag Overrides ({overriddenFlagCount})</H5>
          {Object.entries(flags).map(([flag, enabled]) => (
            <Switch
              key={flag}
              label={flag + (typeof flagOverrides[flag as FlagName] === 'boolean' ? ' *' : '')}
              defaultChecked={enabled}
              onChange={(e) => setFlagOverride(flag as FlagName, e.currentTarget.checked)}
            />
          ))}
          <p className={Classes.TEXT_MUTED}>* overridden</p>
          <Divider className="mb-3" />
          <Button intent="primary" fill onClick={() => window.location.reload()} text="Save" />
          <Button
            className="mt-3"
            fill
            minimal
            onClick={resetFlagOverrides}
            text="Reset Overrides"
          />
        </div>
      }
    >
      {/* Wrapping in fragment prevents popover from affecting link styles */}
      <div className="-ml-2">
        <Button
          minimal
          large
          icon="flag"
          intent={overriddenFlagCount ? 'warning' : 'none'}
          title="Flag Overrides"
          text={overriddenFlagCount}
        />
      </div>
    </Popover>
  );
}
