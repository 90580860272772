import { Callout, CalloutProps } from '@blueprintjs/core';
import { Link } from 'react-router';
import supportLinks from './supportLinks';

export default function LegalInfo(props: CalloutProps) {
  return (
    <Callout {...props}>
      <p className="text-center opacity-80">
        Copyright &copy; {new Date().getFullYear()} Recap Innovations LLC. All Rights Reserved.
      </p>
      <p className="text-center">
        <Link to={supportLinks.about}>About Recap</Link>
        {' • '}
        <Link to={supportLinks.help}>Help</Link>
        {' • '}
        <Link to={supportLinks.privacy}>Privacy</Link>
        {' • '}
        <Link to={supportLinks.terms}>Terms</Link>
        {' • '}
        <Link to={supportLinks.accessibility}>Accessibility</Link>
        {' • '}
        <Link to={supportLinks.security}>Security</Link>
        {' • '}
        <Link to={supportLinks.licenses}>Licenses</Link>
      </p>
    </Callout>
  );
}
