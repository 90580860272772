import {
  browserTracingIntegration,
  captureConsoleIntegration,
  init,
  reactRouterV7BrowserTracingIntegration,
  thirdPartyErrorFilterIntegration,
  wrapCreateBrowserRouterV7,
} from '@sentry/react';
import { useEffect } from 'react';
import {
  createBrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router';

const enabled = process.env.NODE_ENV !== 'development';

if (enabled) {
  init({
    dsn: 'https://c6cef1a548a40d5b8ea35c28fafec9bd@o4508458994368512.ingest.us.sentry.io/4508458995613696',
    integrations: [
      browserTracingIntegration(),
      captureConsoleIntegration(),
      thirdPartyErrorFilterIntegration({
        filterKeys: ['recap'],
        behaviour: 'drop-error-if-exclusively-contains-third-party-frames',
      }),
      reactRouterV7BrowserTracingIntegration({
        useEffect: useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
    ],
    sampleRate: 1.0,
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });

  // Lazy-load Replay
  import('@sentry/browser').then((lazyLoadedSentry) => {
    lazyLoadedSentry.addIntegration(lazyLoadedSentry.replayIntegration());
  });
}

export const sentryCreateBrowserRouter = enabled
  ? wrapCreateBrowserRouterV7(createBrowserRouter)
  : createBrowserRouter;
