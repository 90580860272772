import {
  Button,
  Icon,
  Menu,
  MenuDivider,
  MenuItem,
  OverflowList,
  Popover,
  Tag,
} from '@blueprintjs/core';
import { useQuery } from '@tanstack/react-query';
import { Link, useNavigate } from 'react-router';
import { AuthButton } from '../auth/AuthButtons';
import { signOut, useUserState } from '../auth/UserState';
import { useWorkspaceId, useWorkspaces } from '../auth/WorkspaceState';
import { getPartnerLabel, partners, PartnerType } from '../integrations/partner';
import useIsOperator from '../operator/useIsOperator';
import FlagOverrides from '../util/FlagOverrides';
import { authFetch } from '../util/api';
import { getFlag } from '../util/flags';
import { useIsTranscriptEditor } from '../workspace/permission';
import { NavigationSpinner } from './GlobalSpinner';
import LegalInfo from './LegalInfo';
import RecapNavLink from './RecapNavLink';
import { ReferralBadge } from './ReferralBadge';
import headerLogo from './headerLogo.svg';
import headerLogoWhite from './headerLogoWhite.svg';
import recapLogo from './recapLogo.svg';
import supportLinks from './supportLinks';

const excludedPartners: PartnerType[] = ['rev'];

export default function Header() {
  const { status } = useUserState();
  const { current: workspace } = useWorkspaces();
  const workspaceId = useWorkspaceId();
  const isOperator = useIsOperator();
  const signedIn = status === 'signedIn';
  const links = [
    {
      to: `/workspaces/${workspaceId}/files`,
      label: 'Transcripts',
      show: signedIn && !isOperator,
      end: true,
    },
    ...partners
      .filter((partner) => !excludedPartners.includes(partner))
      .map((partner) => ({
        to: `/workspaces/${workspaceId}/files/${partner}`,
        label: getPartnerLabel(partner),
        show:
          getFlag(partner) &&
          workspace?.activeIntegrations?.some(
            (integration) => integration.platformType === partner,
          ) &&
          !isOperator,
        end: false,
      })),
  ];

  return (
    <nav className="sticky top-0 z-10 bg-white dark:bg-[#202124] h-16 flex gap-x-2 gap-y-6 p-2 sm:p-3 border-gray-200 dark:border-gray-600 border-solid border border-x-0 border-t-0 max-w-[100vw]">
      <a
        href="#main"
        className="sr-only focus:not-sr-only focus:absolute focus:z-50 focus:top-2 focus:left-2 focus:bg-white focus:dark:bg-gray-800 focus:p-4 focus:text-lg focus:border focus:border-gray-300 focus:dark:border-gray-600 "
      >
        Skip to main content
      </a>
      <OverflowList
        minVisibleItems={1}
        items={[
          <Link key="logo" to="/" className="-my-1">
            <picture className="sm:hidden">
              <img alt="Recap" src={recapLogo} height="40" width="40" style={{ marginTop: 4 }} />
            </picture>
            <picture className="hidden sm:inline mr-2">
              <source srcSet={headerLogoWhite} media="(prefers-color-scheme: dark)" />
              <img alt="Recap" src={headerLogo} height="40" width="128" style={{ marginTop: 4 }} />
            </picture>
            <EnvironmentTag />
          </Link>,
          ...links
            .filter((link) => link.show)
            .map(({ to, label, end }) => (
              <RecapNavLink key={to} to={to} end={end}>
                {label}
              </RecapNavLink>
            )),
        ]}
        overflowRenderer={(overflowItems) => <NavPopover items={overflowItems} />}
        visibleItemRenderer={(item) => item}
        observeParents
        collapseFrom="end"
        className="min-w-28"
      />
      <div className="hidden sm:flex grow" />
      {signedIn && workspaceId && !isOperator && getFlag('referrals') && <ReferralBadge />}
      <div className="flex">
        <FlagOverrides />
        {signedIn && (
          <RecapNavLink
            to={supportLinks.help}
            aria-label="Recap Help"
            title="Recap Help"
            target="_blank"
          >
            <Icon icon="help" />
          </RecapNavLink>
        )}

        {signedIn && workspaceId && <WorkspaceSettingsLink />}
        {signedIn && workspaceId && <WorkspaceTranscriptSearchLink />}
        {signedIn && workspace?.type === 'business' && <WorkspaceCorrectionsLink />}
        {signedIn && <AccountPopover />}
      </div>
      {!signedIn && <AuthButton />}
      <NavigationSpinner />
    </nav>
  );
}

function EnvironmentTag() {
  if (getFlag('localApi'))
    return (
      <Tag className="absolute top-10 left-2" intent="success">
        LOCAL
      </Tag>
    );
  if (getFlag('betaApi'))
    return (
      <Tag className="self-center absolute top-10 left-3" intent="warning">
        BETA
      </Tag>
    );
  return null;
}

function NavPopover({ items }: { items: JSX.Element[] }) {
  return (
    <Popover content={<div className="p-3 flex gap-2 flex-col items-start">{items}</div>}>
      <Button minimal rightIcon="chevron-down" text="More…" large />
    </Popover>
  );
}

function AccountPopover() {
  const { user, status } = useUserState();
  const { current: currentWorkspace, workspaces } = useWorkspaces();
  const workspaceId = useWorkspaceId();
  const isOperator = useIsOperator();
  const navigate = useNavigate();
  if (status !== 'signedIn') return null;

  return (
    <Popover
      targetProps={{ style: { display: 'flex' } }}
      className="flex"
      content={
        <>
          <div className="p-3 text-gray-700 dark:text-gray-300 max-w-[300px]">
            {user?.email}
            <div className="mt-2" />
            {user?.displayName}
            <div className="mt-2" />
            {process.env.NODE_ENV === 'development' && user?.uid}
          </div>
          <Menu>
            {!isOperator && (
              <>
                <MenuDivider title="Workspace" />
                {workspaces.map(({ workspace, role }) => (
                  <MenuItem
                    key={workspace.workspaceId}
                    icon={workspace.workspaceId === workspaceId ? 'tick' : 'blank'}
                    text={workspace.workspaceName}
                    labelElement={<Tag minimal>Role: {role}</Tag>}
                    onClick={() => navigate(`/workspaces/${workspace.workspaceId}`)}
                  />
                ))}
                <MenuDivider />
              </>
            )}
            <MenuItem icon="user" text="My Account" onClick={() => navigate('/account')} />
            {isOperator && (
              <MenuItem
                icon="diagram-tree"
                text="Operator Dashboard"
                onClick={() => navigate('/operator')}
              />
            )}
            <MenuItem icon="log-out" text="Sign Out" onClick={signOut} />
            <MenuDivider />
          </Menu>
          <LegalInfo className="max-w-80" minimal />
        </>
      }
    >
      <div className="-ml-2">
        <RecapNavLink
          to="/account"
          onClick={(e) => !(e.ctrlKey || e.metaKey) && e.preventDefault()}
          aria-label="My Account"
          title="My Account"
        >
          <Icon icon="user" />
          <div className="text-xs max-w-[140px] -my-2 hidden md:block">
            {workspaces.length > 1 && (
              <p className="truncate m-0">{currentWorkspace?.workspaceName}</p>
            )}
            <p className="truncate m-0">{user?.displayName || user?.email}</p>
          </div>
        </RecapNavLink>
      </div>
    </Popover>
  );
}

function WorkspaceSettingsLink() {
  const { status } = useUserState();
  const workspaceId = useWorkspaceId();

  if (status !== 'signedIn' || !workspaceId) return <></>;

  return (
    <RecapNavLink
      to={`/workspaces/${workspaceId}/settings`}
      aria-label="Workspace Settings"
      title="Workspace Settings"
    >
      <Icon icon="cog" />
    </RecapNavLink>
  );
}

function WorkspaceTranscriptSearchLink() {
  const { status } = useUserState();
  const workspaceId = useWorkspaceId();

  if (status !== 'signedIn' || !workspaceId) return <></>;

  return (
    <RecapNavLink
      to={`/workspaces/${workspaceId}/search`}
      aria-label="Workspace Transcript Search"
      title="Search Transcripts"
    >
      <Icon icon="search" />
    </RecapNavLink>
  );
}

function WorkspaceCorrectionsLink() {
  const { status } = useUserState();
  const { current: currentWorkspace } = useWorkspaces();
  const isTranscriptEditor = useIsTranscriptEditor();

  const { data } = useQuery({
    queryKey: ['proposedCorrections', currentWorkspace?.workspaceId, 0],
    queryFn: () =>
      authFetch(`/list_proposed_corrections?workspaceId=${currentWorkspace?.workspaceId}&limit=20`),
  });

  if (status !== 'signedIn' || currentWorkspace?.type !== 'business' || !isTranscriptEditor) {
    return <></>;
  }

  return (
    <RecapNavLink
      to={`/workspaces/${currentWorkspace.workspaceId}/corrections`}
      aria-label="Pending Transcript Corrections"
      title="Pending Transcript Corrections"
    >
      <Icon icon="notifications" />
      {data?.corrections?.length > 0 && (
        <Tag intent="danger" round minimal>
          {data.corrections.length}
          {data.corrections.length >= 20 && '+'}
        </Tag>
      )}
    </RecapNavLink>
  );
}
