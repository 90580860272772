import { Classes } from '@blueprintjs/core';
import { Link } from 'react-router';
import supportLinks from '../shared/supportLinks';
import { cx } from '../util';

export const AuthButton = () => (
  <Link to="/auth" className={cx(Classes.BUTTON, Classes.OUTLINED, Classes.LARGE, 'shrink-0')}>
    {/* Sign Up or Log In */}
    Log In
  </Link>
);

export const BookDemoButton = () => (
  <Link
    to={supportLinks.bookDemo}
    className={cx(Classes.BUTTON, Classes.INTENT_PRIMARY, Classes.LARGE, 'shrink-0')}
  >
    Book a Demo
  </Link>
);
