import { Classes } from '@blueprintjs/core';
import { NavLink, NavLinkProps } from 'react-router';
import { cx } from '../util';

function navLinkClassName({ isActive = false, isPending = false }) {
  return cx(
    'relative shrink-0',
    Classes.BUTTON,
    Classes.MINIMAL,
    Classes.LARGE,
    Classes.ALIGN_LEFT,
    isPending && '!cursor-wait',
    isActive &&
      "after:content-[''] after:rounded-sm after:absolute after:left-0 after:right-0 after:bottom-[-12px] after:block after:border-solid after:border-[#673ab7] after:border-[2px] ",
  );
}

export default function RecapNavLink(props: NavLinkProps) {
  return <NavLink className={navLinkClassName} {...props} />;
}
