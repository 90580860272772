import { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import { authFetch } from '../util/api';

export type LanguageMap = Record<string, Language>;
export type VoiceGender = 'MALE' | 'FEMALE';

export type Language = {
  displayName: string;
  languageCode: string;
};

export type VoiceLanguage = {
  name: string;
  languageCodes: string[];
  ssmlGender: VoiceGender;
};

type AvailableLanguageState = {
  transcriptionLanguageMap: LanguageMap;
  translationLanguageMap: LanguageMap;
  combinedLanguageMap: LanguageMap;
  voices: VoiceLanguage[];
  loading: boolean;
};

const loadingState: AvailableLanguageState = {
  transcriptionLanguageMap: {},
  translationLanguageMap: {},
  combinedLanguageMap: {},
  voices: [],
  loading: true,
};

const AvailableLanguageContext = createContext<AvailableLanguageState>(loadingState);

const provider = 'google';
const version = 'v2';

async function getTranscriptionProviderLanguages(): Promise<LanguageMap> {
  const response = await authFetch(
    `/supported_transcriptions?transcriptionProvider=${provider}&displayLanguageCode=en&version=${version}&model=chirp_2`,
  );

  const languageMap = response.results.reduce((langs: LanguageMap, language: any) => {
    langs[language['BCP_47']] = { displayName: language['Name'], languageCode: language['BCP_47'] };
    return langs;
  }, {});
  return languageMap;
}

async function getTranslationProviderLanguages(): Promise<LanguageMap> {
  const response = await authFetch(
    `/supported_translations?translationProvider=${provider}&displayLanguageCode=en`,
  );
  const languageMap = response.languages.reduce((langs: LanguageMap, lang: Language) => {
    langs[lang.languageCode] = lang;
    return langs;
  }, {});
  // TODO re-enable Javanese once https://github.com/googleapis/nodejs-translate/issues/805 is resolved
  delete languageMap.jw;
  return languageMap;
}

async function getVoiceSynthesisProviderLanguages(): Promise<VoiceLanguage[]> {
  const response = await authFetch(`/supported_voices?voiceProvider=${provider}`);
  return response.voices;
}

// Provider used in App.tsx to load available transcription and translation languages, and synthetic voices.
export function AvailableLanguageProvider({ children }: { children: ReactNode }) {
  const [state, setState] = useState<AvailableLanguageState>(loadingState);

  useEffect(() => {
    Promise.all([
      getTranscriptionProviderLanguages(),
      getTranslationProviderLanguages(),
      getVoiceSynthesisProviderLanguages(),
    ])
      .then(([transcriptionLanguageMap, translationLanguageMap, voiceLanguageMap]) => {
        setState({
          transcriptionLanguageMap,
          translationLanguageMap,
          // Spread transcription second to prioritize those languages if there are duplicates
          combinedLanguageMap: { ...translationLanguageMap, ...transcriptionLanguageMap },
          voices: voiceLanguageMap,
          loading: false,
        });
      })
      .catch((error) => {
        console.error('Failed to load translation provider languages', error);
      });
  }, []);
  return (
    <AvailableLanguageContext.Provider value={state}>{children}</AvailableLanguageContext.Provider>
  );
}

// Hook used in components to access the maps of available languages.
export function useAvailableLanguages(): AvailableLanguageState {
  const context = useContext(AvailableLanguageContext);
  if (context === undefined) {
    throw new Error('useAvailableLanguages must be used within an AvailableLanguageProvider');
  }
  return context;
}
