import { FocusStyleManager } from '@blueprintjs/core';
import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import './blueprintTheme.scss';
import './index.css';

FocusStyleManager.onlyShowFocusOnTabs();

function setDarkTheme(darkModeOn: boolean) {
  document.body.classList.toggle('bp5-dark', darkModeOn);
  document.body.classList.toggle('dark', darkModeOn);
}
const darkModeMediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
setDarkTheme(darkModeMediaQuery.matches);
darkModeMediaQuery.addEventListener('change', (e) => setDarkTheme(e.matches));

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
  <StrictMode>
    <App />
  </StrictMode>,
);
