import { deletePref, getPref, setPref } from './prefs';

/*
Very simple feature flags implementation:
- Flags are defined in `defaultFlags` and a re-deploy is required to update defaults
- Flag overrides are stored in localStorage
- See FlagOverrides.tsx for the override UI
*/

const isLocalOrBeta =
  process.env.NODE_ENV === 'development' || window.location.hostname.startsWith('beta');

// Add new flags with default values (boolean only) here
const defaultFlags = {
  localApi: false,
  betaApi: isLocalOrBeta,
  sso: isLocalOrBeta,
  referrals: true,

  // Partner integrations
  brightcove: isLocalOrBeta,
  box: true,
  canvas: isLocalOrBeta,
  dropbox: isLocalOrBeta,
  googledrive: true,
  kaltura: true,
  mux: isLocalOrBeta,
  panopto: isLocalOrBeta,
  rev: true,
  vimeo: isLocalOrBeta,
  wistia: isLocalOrBeta,
  youtube: true,
};

export type FlagName = keyof typeof defaultFlags;
export type FlagConfig = Record<FlagName, boolean>;
export type FlagOverridesConfig = Partial<FlagConfig>;

let savedOverrides: undefined | FlagOverridesConfig = undefined;

function loadFlags() {
  if (!savedOverrides) {
    try {
      savedOverrides = getPref('flagOverrides', {});
    } catch (error) {
      savedOverrides = {};
    }
  }
}

export function getFlags(): FlagConfig {
  loadFlags();
  return { ...defaultFlags, ...savedOverrides };
}

export function getFlagOverrides(): FlagOverridesConfig {
  loadFlags();
  return savedOverrides as FlagOverridesConfig;
}

export function getFlag(flag: FlagName): boolean {
  loadFlags();
  return savedOverrides?.[flag] ?? defaultFlags[flag];
}

export function setFlagOverride(flag: FlagName, enabled: boolean): void {
  loadFlags();
  if (defaultFlags[flag] === enabled) {
    delete (savedOverrides as FlagOverridesConfig)[flag];
  } else {
    (savedOverrides as FlagOverridesConfig)[flag] = enabled;
  }
  setPref('flagOverrides', savedOverrides);
}

export function resetFlagOverrides(): void {
  deletePref('flagOverrides');
  window.location.reload();
}
