import { Button, Callout } from '@blueprintjs/core';
import { signOut } from './UserState';

export default function LoadError() {
  return (
    <Callout intent="danger" title="Unable to load Recap" className="max-w-prose m-auto mt-12">
      Check your internet connection and try again. If that doesn’t work, please log out and log
      back in.
      <div className="flex gap-2 flex-wrap my-2">
        <Button onClick={() => window.location.reload()}>Try Again</Button>
        <Button onClick={signOut}>Log Out</Button>
      </div>
    </Callout>
  );
}
