import { useEffect } from 'react';
import config from '../config';
import { getFlag } from './flags';

let lastWarmTime = 0;

export default function useCloudFunctionWarmer() {
  useEffect(() => {
    const alreadyWarm = Date.now() - lastWarmTime < 60000;
    if (!getFlag('localApi') && !alreadyWarm) {
      const url = `${process.env.NODE_ENV === 'development' ? config.apiBase : ''}/api/warmup`;
      fetch(url).catch(() => {});
      lastWarmTime = Date.now();
    }
  }, []);
}
