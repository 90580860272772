import { Navigate, Outlet, createPath, useLocation } from 'react-router';
import DelayedSpinner from '../shared/DelayedSpinner';
import LoadError from './LoadError';
import { useUserState } from './UserState';

export default function RequireAuth() {
  const userState = useUserState();
  const location = useLocation();

  switch (userState.status) {
    case 'signedIn':
      return <Outlet />;
    case 'signedOut':
      return <Navigate to={`/auth?from=${encodeURIComponent(createPath(location))}`} replace />;
    case 'loading':
      return <DelayedSpinner className="my-8" />;
    case 'error':
      return <LoadError />;
  }
}
