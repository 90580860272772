import { initializeApp } from 'firebase/app';
import localIp from './localIp';
import { getFlag } from './util/flags';

const localConfig = {
  apiBase: '/localApi',
  firebaseConfig: {
    apiKey: 'AIzaSyAfQvRE1v-ddLwVFklZeKk2YqXdV8QP-I8',
    authDomain: 'voicerecap-dev.firebaseapp.com',
    projectId: 'voicerecap-dev',
    storageBucket: 'voicerecap-dev.appspot.com',
    messagingSenderId: '903791485961',
    appId: '1:903791485961:web:da82232205b49a4c7b84d8',
  },
  magicPublishableKey: 'pk_live_5ADEABFBC3086CA7',
  swaggerUrl: 'https://voicerecap-dev.web.app/swagger-ui/',
  googleAnalyticsMeasurementId: null,
};

const betaConfig = {
  // The create-react-app proxy handles API requests in development: https://create-react-app.dev/docs/proxying-api-requests-in-development/
  apiBase:
    process.env.NODE_ENV === 'development'
      ? '/betaApi'
      : 'https://us-central1-voicerecap-dev.cloudfunctions.net',
  firebaseConfig: {
    apiKey: 'AIzaSyAfQvRE1v-ddLwVFklZeKk2YqXdV8QP-I8',
    authDomain: 'voicerecap-dev.firebaseapp.com',
    projectId: 'voicerecap-dev',
    storageBucket: 'voicerecap-dev.appspot.com',
    messagingSenderId: '903791485961',
    appId: '1:903791485961:web:da82232205b49a4c7b84d8',
  },
  magicPublishableKey: 'pk_live_5ADEABFBC3086CA7',
  swaggerUrl: 'https://voicerecap-dev.web.app/swagger-ui/',
  googleAnalyticsMeasurementId: 'G-C9NND8B8N4',
};

const prodConfig = {
  // The create-react-app proxy handles API requests in development: https://create-react-app.dev/docs/proxying-api-requests-in-development/
  apiBase:
    process.env.NODE_ENV === 'development'
      ? '/prodApi'
      : 'https://us-central1-griffinscribe-656d9.cloudfunctions.net',
  firebaseConfig: {
    apiKey: 'AIzaSyCE_0OFKPLZjHM-QBj0MxnrLfrcpMa6srA',
    authDomain: 'griffinscribe-656d9.firebaseapp.com',
    projectId: 'griffinscribe-656d9',
    storageBucket: 'griffinscribe-656d9.appspot.com',
    messagingSenderId: '1080603572087',
    appId: '1:1080603572087:web:afb1440c126089e21f1372',
  },
  magicPublishableKey: 'pk_live_CA78F24781D3BBF6',
  swaggerUrl: 'https://griffinscribe-656d9.web.app/swagger-ui/',
  googleAnalyticsMeasurementId: 'G-YJHVEE502D',
};

const config = (() => {
  if (getFlag('localApi')) return localConfig;
  if (getFlag('betaApi')) return betaConfig;
  return prodConfig;
})();

export default config;

export const firebaseApp = initializeApp(config.firebaseConfig);

// Lazy-load to avoid bringing unnecessary deps into the main index.js
import('./firebaseAnalytics');
if (getFlag('localApi')) {
  (async () => {
    const { connectAuthEmulator, getAuth } = await import('firebase/auth');
    const { connectStorageEmulator, getStorage } = await import('firebase/storage');
    connectAuthEmulator(getAuth(firebaseApp), `http://${localIp}:9099`);
    connectStorageEmulator(getStorage(firebaseApp), localIp, 9199);
  })();
}
