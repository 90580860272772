export const partners = [
  'box',
  'brightcove',
  'canvas',
  'dropbox',
  'googledrive',
  'kaltura',
  'mux',
  'panopto',
  'rev',
  'vimeo',
  'wistia',
  'youtube',
] as const;
export type IntegrationType = (typeof partners)[number];
export type PartnerType = IntegrationType | 'recap';

export function getPartnerLabel(partner: PartnerType): string {
  switch (partner) {
    case 'box':
      return 'Box';
    case 'brightcove':
      return 'Brightcove';
    case 'canvas':
      return 'Canvas';
    case 'dropbox':
      return 'Dropbox';
    case 'googledrive':
      return 'Google Drive';
    case 'kaltura':
      return 'Kaltura';
    case 'mux':
      return 'Mux';
    case 'panopto':
      return 'Panopto';
    case 'recap':
      return 'Recap';
    case 'rev':
      return 'Rev';
    case 'vimeo':
      return 'Vimeo';
    case 'wistia':
      return 'Wistia';
    case 'youtube':
      return 'YouTube';
  }
}

export function getPartnerDownloadLink(partner: PartnerType): string {
  switch (partner) {
    case 'recap':
      return '';
    case 'youtube':
      return 'https://support.google.com/youtube/answer/56100';
    case 'brightcove':
      return 'https://studio.support.brightcove.com/media/manage/downloading-videos-video-cloud.html';
    case 'panopto':
      return 'https://support.panopto.com/s/article/How-to-Download-a-Session-as-an-MP4';
    case 'vimeo':
      return 'https://vimeo.com/blog/post/how-to-download-videos-from-vimeo/';
    default:
      return '';
  }
}
