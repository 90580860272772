import { AnchorButton, NonIdealState } from '@blueprintjs/core';
import { useUserState } from './UserState';

export default function WorkspaceNotFound() {
  const { user, authClaims } = useUserState();
  const defaultWorkspaceId = authClaims?.workspaces?.[0]?.id;
  return (
    <NonIdealState
      title="Workspace Not Found"
      description={`This workspace does not exist, or ${
        user?.email || 'your current account'
      } does not have
      permission to access it. If you were recently invited to a workspace, please click the “Accept Invite” button in the email you received, or contact your workspace administrator to send you a new invitation.`}
      icon="high-priority"
      action={
        <AnchorButton href={`/workspaces/${defaultWorkspaceId}/files`} intent="primary" large>
          Go Home
        </AnchorButton>
      }
    />
  );
}
